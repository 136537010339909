import React, { useEffect, useState } from "react";
import "./CustomNavbar.scss";
import { Link, useNavigate } from "react-router-dom"; // If you're using React Router for routing
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import img1 from "../../assits/logo-n-2-removebg-preview.png";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function FadeMenu({ lang, profile }) {
  const [t, i18n] = useTranslation();
  const [lan, setLang] = useLocalStorage("lang", i18n.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  console.log("i18n.language==>", i18n.language);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    i18n.changeLanguage(lan);
  }, [lan]);

  return (
    <div className="dropdown12">
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {lang && <LanguageIcon />}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {lang && (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                i18n.changeLanguage("en");
                setLang(i18n.language);
              }}
            >
              {t("english")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                i18n.changeLanguage("fr");
                setLang(i18n.language);
              }}
            >
              {t("French")}
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
}

const CustomNavbar = () => {
  const [t, i18n] = useTranslation();
  return (
    <Navbar expand="lg" className="navnav">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Navbar.Brand href="/">
          <img
            src={img1} // Replace with your logo image path
            className="d-inline-block align-top"
            alt="Lodadasdgo"
          />
        </Navbar.Brand>
        <Nav className="ms-auto gap-4">
          <a href="#home" className="nav-link">
            {t("Home")}
          </a>
          <a href="#about" className="nav-link">
            {t("About")}
          </a>
          <a href="#Features" className="nav-link">
            {t("Features")}
          </a>
          <a href="#contact-us" className="nav-link">
            {t("Contact Us")}
          </a>
        </Nav>
        <div className="icon-topbar">
          <FadeMenu lang="lang" />
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
