import React, { useRef, useState } from "react";
import "./ContactUs.scss";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { notifyError, notifysuccess } from "../../Notification";

function ContactUs() {
  const [t, i18n] = useTranslation();
  const [isPending, setPending] = useState(false);

  const form = useRef();
  let [check, setCheck] = useState({
    checkk: "",
  });
  console.log("check=>", check);

  const sendEmail = (e) => {
    e.preventDefault();

    if (check.checkk === "16" && !isPending) {
      setPending(true);

      emailjs
        .sendForm(
          `${i18n.language === "en" ? "service_18h3rkx" : "service_8av95h8"}`,
          `${i18n.language === "en" ? "template_mpwrfeo" : "template_ebh24bp"}`,
          form.current,
          `${
            i18n.language === "en" ? "H8ReQGz1oj6S-7TOX" : "MAYwCv8NNP9ZIsZzo"
          }`
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
            e.target.reset();
            setCheck("");
            notifysuccess("succeeded");
            setfocusfirstname(true);
          },
          (error) => {
            console.log(error.text);
            notifyError("failed");
          }
        )
        .finally(() => {
          setPending(false);
          setfocusfirstname(false);
        });
    }
  };
  let [focusfirstname, setfocusfirstname] = useState(false);
  let getdata = (e) => {
    setCheck((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let bluefn = (e) => {
    if (e === "checkk") {
      setfocusfirstname(true);
    }
  };
  return (
    <div className="contact-us " id="contact-us">
      <h2 className="text-center">{t("Contact Us")}</h2>
      <form
        className="font-contact-us container"
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="row">
          <div className="col-lg-6">
            <input
              className="form-control"
              type="text"
              placeholder={t("Your Name")}
              name="user_name"
              required
            />
          </div>
          <div className="col-lg-6">
            <input
              className="form-control"
              type="email"
              placeholder={t("Your Email")}
              name="user_email"
              required
            />
          </div>
          <div className="col-lg-12">
            <input
              className="form-control"
              type="text"
              placeholder={t("Subject")}
              name="user_subject"
              required
            />
          </div>
          <div className="col-lg-12">
            <textarea
              className="form-control mb-2"
              placeholder={t("Message")}
              cols="30"
              name="message"
              rows="10"
              required
            ></textarea>
          </div>
          <div className="col-lg-12 det-inppp">
            <h>{t("please answer the following question")} : </h>
            <input
              onChange={(e) => {
                getdata(e);
              }}
              className="form-control"
              type="text"
              placeholder="12+4"
              required
              name="checkk"
              pattern="16"
              onBlur={() => {
                bluefn("checkk");
              }}
              focused={focusfirstname.toString()}
            />
            <span>
              {i18n.language === "en"
                ? "Your Answer is Wrong"
                : "Votre réponse est fausse"}
            </span>
          </div>
          <button
            type="submit"
            className="m-auto btn btn-primary"
            disabled={isPending}
          >
            {isPending ? "Sending..." : t("Send")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
