import React from "react";
import "./About.scss";
import { useTranslation } from "react-i18next";
import img1 from "../../assits/certi.jpg";
import img2 from "../../assits/about-2.jpg";
import img3 from "../../assits/asia.jpg";
import img4 from "../../assits/global.jpg";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import SchoolIcon from "@mui/icons-material/School";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
function About() {
  const [t, i18n] = useTranslation();
  return (
    <div className="about" id="about">
      <div className="header-about text-center"></div>
      <div className="top-box row">
        <div className="col-lg-6">
          <div className="box-top-image"></div>
        </div>
        <div className="col-lg-6">
          <div className="title header-about">
            <h2>{t("About Us")}</h2>
            <p>
              <ArrowRightIcon color="#266562" />
              {t(
                "A professional and independent Halal certification body with extensive experience with the largest international companies"
              )}
            </p>
            <p>
              <ArrowRightIcon />
              {t(
                "The International Halal Center is not just a Halal certification body, but rather a strategic partner for companies wishing to enter the Halal markets or that are already operating in these markets and wish to obtain a higher level of professional and globally accrdited service."
              )}
            </p>
            <p>
              <ArrowRightIcon />
              {t(
                "Through a professional and trained team, capable of understanding the global industries challenges, International Halal Center always strives to develop a new working methods and find solutions to provide the best services in the shortest possible time."
              )}
            </p>
            <p>
              <ArrowRightIcon />
              {t(
                "International Halal Center offers solutions not only to large international companies,  but also to medium and small businesses through a set of working methods specifically designed for each category while considering the specific challenges and needs"
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid farm" id="Features">
        <h2 className="text-center">{t("Our Features")}</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="farm-big-box row container m-auto">
              <div className="col-lg-3 col-md-6">
                <div className="farm-box ">
                  <div className="box-img">
                    <img src={img1} alt="" />
                  </div>
                  <SchoolIcon className="icon" />
                  <p>
                    {t(
                      "Grants its customers internationally recognized certificates with the highest level of accreditation, through its branches and partners located throughout the world"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="farm-box ">
                  <div className="box-img">
                    <img src={img4} alt="" />
                  </div>
                  <CallMergeIcon className="icon" />
                  <p>
                    {t(
                      "Through its branches and partners, its activities cover all countries of the European Union, North and South America, Africa and the Middle East."
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="farm-box ">
                  <div className="box-img">
                    <img src={img3} alt="" />
                  </div>
                  <PublicIcon className="icon" />
                  <p>
                    {t(
                      "Opens the doors for its customers to export towards all Halal markets in Arab and Islamic  countries, for example Indonesia, Malaysia, Singapore, the Emirates, Saudi Arabia, Qatar,  Kuwait and other countries that require Halal certificates for imported products."
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="farm-box ">
                  <div className="box-img">
                    <img src={img2} alt="" />
                  </div>
                  <CurrencyExchangeIcon className="icon" />
                  <p>
                    {t(
                      "Aware of the financial challenges in global markets, its prices are carefully studied and  established according to the size of the companies and the type of industries and product, which makes them suitable for all categories of industries and all sizes of businesses"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
