import React from "react";
import "./Header.scss";
import CustomNavbar from "../Navbar/CustomNavbar";
import { useTranslation } from "react-i18next";

function Header() {
  const [t, i18n] = useTranslation();
  return (
    <div className="header">
      <CustomNavbar />
      <div className="container-fluid">
        <div className="content">
          <h1>
            <span>{t("International Halal Center")}</span>
            <br />
            {t("Global solutions without complications")}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Header;
