import React, { useEffect, useState } from "react";
import "./Home.scss";
import Header from "../../components/Header/Header";
import About from "../../components/About/About";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import NorthIcon from "@mui/icons-material/North";
function Home() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 400) {
      setShowScroll(true);
    } else if (showScroll && window.scrollY <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div>
      <div>{/* Your component content */}</div>
      <Header />
      <About />
      <ContactUs />
      <Footer />

      <span
        className={`scroll-top-button ${
          showScroll ? "shoshoww" : "nonon"
        } icon-top-top`}
        onClick={scrollTop}
      >
        <NorthIcon />
      </span>
    </div>
  );
}

export default Home;
