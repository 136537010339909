import React from "react";
import "./NotFound.scss";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="notfound">
      <p>Page Not Found</p>
      <Link to="/">
        <span className="btn btn-primary">Back To Home</span>
      </Link>
    </div>
  );
}

export default NotFound;
