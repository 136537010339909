import React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import img1 from "../../assits/logo-r.png";
function Footer() {
  const [t, i18n] = useTranslation();
  console.log("i18n==>", i18n.language);
  return (
    <div className="footer">
      {i18n.language === "en" ? (
        <div className="row">
          <div className="col-lg-4  mb-3">
            <div className="logo">
              <img src={img1} alt="" />
            </div>
          </div>
          <div className="col-lg-4  mb-3">
            <p>International Halal Center GmbH</p>
            <p>Mirjam-Pressler-Str. 10</p>
            <p>64289 Darmstadt, Germany</p>
          </div>
          <div className="col-lg-4  mb-3">
            <p>
              Email:{" "}
              <a href="mailto:info@halalcenter.global">
                info@halalcenter.global
              </a>
            </p>
            <p>Phone: +49 6151 8003143</p>
            <p>Website: www.halalcenter.global</p>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="logo">
              <img src={img1} alt="" />
            </div>
          </div>

          <div className="col-lg-4 mb-3">
            <p>International Halal Center SAS</p>
            <p>25 Place de la madeleine</p>
            <p>75008 Paris, France</p>
          </div>
          <div className="col-lg-4 mb-3">
            <p>
              <a href="mailto:ihc-france@halalcenter.eu">
                ihc-france@halalcenter.eu
              </a>
            </p>
            <p>Téléphone : +33 (0) 183642274</p>
            <p>Site : www.halalcenter.global</p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="copyRight col-lg-12 text-center">
          <hr />
          <p>© Copyright © 2023 International Halal Center</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
